<script setup lang="ts">
import { ImgHTMLAttributes, computed } from 'vue'
import type { Picture, Source } from 'vite-imagetools'

const props = defineProps<{
    picture: Picture
    class?: string
    alt?: string
    fetchPriority?: 'auto' | 'high' | 'low'
    loading?: ImgHTMLAttributes['loading']
}>()

const attributes = computed<ImgHTMLAttributes>(() => ({
    src: props.picture.img.src,
    alt: props.alt,
    class: props.class,
    loading: props.loading,
    fetchpriority: props.fetchPriority,
}))

const srcsetString = (srcset: Source[]) => srcset.map(({ src, w }) => `${src} ${w}w`).join(', ')
</script>

<template>
    <picture>
        <source
            v-for="(sources, format) in picture.sources"
            :key="format"
            :srcset="srcsetString(sources)"
            :type="`image/${format}`"
        >

        <img v-bind="attributes">
    </picture>
</template>
