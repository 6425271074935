<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Link, usePage } from '@inertiajs/vue3'
import { useI18n } from 'vue-i18n'

import Image from '@/Components/Image.vue'
import JewelleryCoverImage from '@images/categories/hero/jewellery.jpg?format=webp;jpg&as=picture&imagetools'
import JewelleryKidsCoverImage from '@images/categories/hero/jewellery-kids.jpg?format=webp;jpg&as=picture&imagetools'
import SilverTablewareCoverImage from '@images/categories/hero/silver-tableware.jpg?format=webp;jpg&as=picture&imagetools'

import 'swiper/css'

const page = usePage()
const { t } = useI18n()

const jewelleryCategory = page.props.layout.categories.find(category => category.code === 'jewellery')
const kidsJewelleryCategory = page.props.layout.categories.find(category => category.code === 'kids-jewellery')
const silverwareCategory = page.props.layout.categories.find(category => category.code === 'silverware')
</script>

<template>
    <section>
        <Swiper
            :loop="true"
            :speed="500"
            :slides-per-view="1"
            :breakpoints="{ 768: { slidesPerView: 2, loop: false, rewind: true }, 1024: { slidesPerView: 3 } }"
            class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3"
        >
            <SwiperSlide
                v-if="jewelleryCategory"
                class="col-span-1"
            >
                <div class="flex flex-col bg-center bg-no-repeat bg-cover">
                    <Image
                        :picture="JewelleryCoverImage"
                        :alt="jewelleryCategory.name"
                        class="absolute inset-0 object-cover object-center w-full h-full"
                    />

                    <div
                        class="group relative flex flex-col bg-black bg-opacity-5 text-white text-center cursor-pointer transition-colors ease-in-out duration-200 hover:bg-opacity-[0.15]"
                        style="min-height: 470px;"
                    >
                        <div class="px-6 py-10 mt-auto -mb-20">
                            <h2 class="text-4xl font-bold">{{ jewelleryCategory.name }}</h2>
                        </div>

                        <div class="px-6 py-10 mt-auto">
                            <Link
                                class="px-8 py-3 text-sm font-medium text-black bg-white border border-transparent"
                                :href="route('shop.category', jewelleryCategory.slug_path)"
                            >
                            {{ t('pages.home.hero-slider.shop-jewellery') }}
                            <span
                                class="inline-block transition duration-200 ease-in-out group-hover:translate-x-1"
                                aria-hidden="true"
                            >&rarr;</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </SwiperSlide>

            <SwiperSlide
                v-if="kidsJewelleryCategory"
                class="hidden col-span-1 md:block"
            >
                <div class="flex flex-col bg-center bg-no-repeat bg-cover">
                    <Image
                        :picture="JewelleryKidsCoverImage"
                        :alt="kidsJewelleryCategory.name"
                        class="absolute inset-0 object-cover object-center w-full h-full"
                        loading="lazy"
                    />

                    <div
                        class="group relative flex flex-col bg-black bg-opacity-20 text-white text-center cursor-pointer transition-colors ease-in-out duration-200 hover:bg-opacity-[0.25]"
                        style="min-height: 470px;"
                    >
                        <div class="px-6 py-10 mt-auto -mb-20">
                            <h2 class="text-4xl font-bold">
                                {{ kidsJewelleryCategory.name }}
                            </h2>
                        </div>

                        <div class="px-6 py-10 mt-auto">
                            <Link
                                class="px-8 py-3 text-sm font-medium text-black bg-white border border-transparent"
                                :href="route('shop.category', kidsJewelleryCategory.slug_path)"
                            >
                            {{ t('pages.home.hero-slider.shop-jewellery') }}
                            <span
                                class="inline-block transition duration-200 ease-in-out group-hover:translate-x-1"
                                aria-hidden="true"
                            >&rarr;</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </SwiperSlide>

            <SwiperSlide
                v-if="silverwareCategory"
                class="hidden col-span-1 lg:block"
            >
                <div class="flex flex-col bg-center bg-no-repeat bg-cover">
                    <Image
                        :picture="SilverTablewareCoverImage"
                        :alt="silverwareCategory.name"
                        class="absolute inset-0 object-cover object-center w-full h-full"
                        loading="lazy"
                    />

                    <div
                        class="group relative flex flex-col bg-black bg-opacity-20 text-white text-center cursor-pointer transition-colors ease-in-out duration-200 hover:bg-opacity-[0.25]"
                        style="min-height: 470px;"
                    >
                        <div class="px-6 py-10 mt-auto -mb-20">
                            <h2 class="text-4xl font-bold">
                                {{ silverwareCategory.name }}
                            </h2>
                        </div>

                        <div class="px-6 py-10 mt-auto">
                            <Link
                                class="px-8 py-3 text-sm font-medium text-black bg-white border border-transparent"
                                :href="route('shop.category', silverwareCategory.slug_path)"
                            >
                            {{ silverwareCategory.name }}
                            <span
                                class="inline-block transition duration-200 ease-in-out group-hover:translate-x-1"
                                aria-hidden="true"
                            >&rarr;</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </SwiperSlide>
        </Swiper>
    </section>
</template>
